import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
// import Img from "gatsby-image"

import ModalConsult from "../Global/ModalConsult"

import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import NavDropdown from "react-bootstrap/NavDropdown"
// import Dropdown from "react-bootstrap/Dropdown"
// import Button from 'react-bootstrap/Button'

import LogoImg from "../../../static/i/logo-center-licenziy.svg"

const Header = ({ lang, serviceId }) => (
  <StaticQuery
    query={graphql`
      {
        uk: allContentfulService(
          filter: { node_locale: { eq: "uk" } }
          sort: { fields: serviceId, order: ASC }
        ) {
          nodes {
            department {
              logo {
                fixed(width: 16, height: 16) {
                  ...GatsbyContentfulFixed
                }
              }
            }
            serviceId
            title
            shortName
            id
            price
            ltdPrice
            termFrom
            termUpTo
            validity
            taxAmount
            seo {
              slug
              metaImage {
                fluid {
                  src
                }
              }
            }
          }
        }
        ru: allContentfulService(
          filter: { node_locale: { eq: "ru" } }
          sort: { fields: serviceId, order: ASC }
        ) {
          nodes {
            department {
              logo {
                fixed(width: 16, height: 16) {
                  ...GatsbyContentfulFixed
                }
              }
            }
            serviceId
            title
            shortName
            id
            price
            ltdPrice
            termFrom
            termUpTo
            validity
            taxAmount
            seo {
              slug
              metaImage {
                fluid {
                  src
                }
              }
            }
          }
        }
        licenseuk: allContentfulService(
          sort: { order: ASC, fields: serviceId }
          filter: {
            node_locale: { eq: "uk" }
            type: { type: { eq: "Ліцензія" } }
            department: {
              name: { eq: "Державна Служба України з Безпеки на Транспорті" }
            }
          }
        ) {
          nodes {
            department {
              logo {
                fixed(width: 16, height: 16) {
                  ...GatsbyContentfulFixed
                }
              }
            }
            serviceId
            title
            shortName
            id
            price
            ltdPrice
            termFrom
            termUpTo
            validity
            taxAmount
            seo {
              slug
              metaImage {
                fluid {
                  src
                }
              }
            }
          }
        }
        licenseru: allContentfulService(
          sort: { order: ASC, fields: serviceId }
          filter: {
            node_locale: { eq: "ru" }
            type: { type: { eq: "Лицензия" } }
            department: {
              name: {
                eq: "Государственная Служба Украины по Безопасности на Транспорте"
              }
            }
          }
        ) {
          nodes {
            department {
              logo {
                fixed(width: 16, height: 16) {
                  ...GatsbyContentfulFixed
                }
              }
            }
            serviceId
            title
            shortName
            id
            price
            ltdPrice
            termFrom
            termUpTo
            validity
            taxAmount
            seo {
              slug
              metaImage {
                fluid {
                  src
                }
              }
            }
          }
        }
        servuk: allContentfulService(
          sort: { order: ASC, fields: serviceId }
          filter: {
            node_locale: { eq: "uk" }
            type: { type: { eq: "Послуга" } }
          }
        ) {
          nodes {
            department {
              logo {
                fixed(width: 16, height: 16) {
                  ...GatsbyContentfulFixed
                }
              }
            }
            serviceId
            title
            shortName
            id
            price
            ltdPrice
            termFrom
            termUpTo
            validity
            taxAmount
            seo {
              slug
              metaImage {
                fluid {
                  src
                }
              }
            }
          }
        }

        servru: allContentfulService(
          sort: { order: ASC, fields: serviceId }
          filter: {
            node_locale: { eq: "ru" }
            type: { type: { eq: "Услуга" } }
          }
        ) {
          nodes {
            department {
              logo {
                fixed(width: 16, height: 16) {
                  ...GatsbyContentfulFixed
                }
              }
            }
            serviceId
            title
            shortName
            id
            price
            ltdPrice
            termFrom
            termUpTo
            validity
            taxAmount
            seo {
              slug
              metaImage {
                fluid {
                  src
                }
              }
            }
          }
        }
        medlicuk: allContentfulService(
          sort: { order: ASC, fields: serviceId }
          filter: {
            node_locale: { eq: "uk" }
            type: { type: { eq: "Ліцензія" } }
            department: {
              name: { eq: "Міністерство Охорони Здоров'я України" }
            }
          }
        ) {
          nodes {
            department {
              id
              logo {
                fixed(width: 16, height: 16) {
                  ...GatsbyContentfulFixed
                }
              }
            }
            serviceId
            title
            shortName
            id
            price
            ltdPrice
            termFrom
            termUpTo
            validity
            taxAmount
            seo {
              slug
              metaImage {
                fluid {
                  src
                }
              }
            }
          }
        }
        medlicru: allContentfulService(
          sort: { order: ASC, fields: serviceId }
          filter: {
            node_locale: { eq: "ru" }
            type: { type: { eq: "Лицензия" } }
            department: { name: { eq: "Министерство Здравоохранения Украины" } }
          }
        ) {
          nodes {
            department {
              id
              logo {
                fixed(width: 16, height: 16) {
                  ...GatsbyContentfulFixed
                }
              }
            }
            serviceId
            title
            shortName
            id
            price
            ltdPrice
            termFrom
            termUpTo
            validity
            taxAmount
            seo {
              slug
              metaImage {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <header>
        <Nav justify className="top-menu-row justify-content-center">
          <Nav.Item>
            {serviceId === 7 ? (
              <a
                href="tel:+380678190272"
                className="top-menu-phone binct-phone-number-3 text-nowrap"
              >
                +38(067) 819-02-72
              </a>
            ) :
              serviceId === 8 ? (
              <>
                <a
                  href="tel:+380683328808"
                  className="top-menu-phone binct-phone-number-1 text-nowrap"
                >
                  +38(068) 332-88-08
                </a>
                ,{" "}
                <a
                  href="tel:+380678190272"
                  className="top-menu-phone binct-phone-number-3 text-nowrap"
                >
                  +38(067) 819-02-72
                </a>
              </>
              ):(
                <>
                  <a
                    href="tel:+380683328808"
                    className="top-menu-phone binct-phone-number-1 text-nowrap"
                  >
                    +38(068) 332-88-08
                  </a>
                  ,{" "}
                  <a
                    href="tel:+380994440124"
                    className="top-menu-phone binct-phone-number-2 text-nowrap"
                  >
                    +38(099) 444-01-24
                  </a>
                </>
              )
              
            }
          </Nav.Item>
        </Nav>
        <Navbar bg="light" expand="lg">
          <Navbar.Brand
            className="image-logo d-flex"
            as={Link}
            to={lang === "ru" ? `/ru/` : `/`}
          >
            <img
              src={LogoImg}
              alt={
                lang === "ru"
                  ? `Логотип Центра Лицензий`
                  : `Логотип Центру Ліцензій`
              }
              className="img-responsive"
            />
            <span className="logo">
              {lang === "ru" ? `Центр Лицензий` : `Центр Ліцензій`}
              <small className="slogan">
                {lang === "ru"
                  ? `Лицензии Украины`
                  : `Державні Ліцензії України`}
              </small>
            </span>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {lang === "ru" ? (
                <>
                  <NavDropdown
                    title="Транспортные лицензии"
                    id="basic-nav-dropdown"
                    className="text-uppercase root-menu"
                  >
                    {data.licenseru.nodes.map(service => (
                      <li key={service.id} className="text-normalcase">
                        <NavDropdown.Item
                          as={Link}
                          to={`/ru/${service.seo.slug}/`}
                          activeClassName="active"
                        >
                          {service.shortName}
                        </NavDropdown.Item>
                      </li>
                    ))}
                  </NavDropdown>
                  <NavDropdown
                    title="Медицинские лицензии"
                    id="basic-nav-dropdown"
                    className="text-uppercase root-menu"
                  >
                    {data.medlicru.nodes.map(service => (
                      <li key={service.id} className="text-normalcase">
                        <NavDropdown.Item
                          as={Link}
                          to={`/ru/${service.seo.slug}/`}
                          activeClassName="active"
                        >
                          {service.shortName}
                        </NavDropdown.Item>
                      </li>
                    ))}
                  </NavDropdown>
                  <NavDropdown
                    title="Перевозчикам"
                    id="basic-nav-dropdown"
                    className="text-uppercase root-menu"
                  >
                    {data.servru.nodes.map(service => (
                      <li key={service.id} className="text-normalcase">
                        <NavDropdown.Item
                          as={Link}
                          to={`/ru/${service.seo.slug}/`}
                          activeClassName="active"
                        >
                          {service.shortName}
                        </NavDropdown.Item>
                      </li>
                    ))}
                  </NavDropdown>
                </>
              ) : (
                <>
                  <NavDropdown
                    title="Транспортні ліцензії"
                    id="basic-nav-dropdown"
                    className="text-uppercase root-menu"
                  >
                    {data.licenseuk.nodes.map(service => (
                      <li key={service.id} className="text-normalcase">
                        <NavDropdown.Item
                          as={Link}
                          to={`/${service.seo.slug}/`}
                          activeClassName="active"
                        >
                          {service.shortName}
                        </NavDropdown.Item>
                      </li>
                    ))}
                  </NavDropdown>
                  <NavDropdown
                    title="Медичні ліцензії"
                    id="basic-nav-dropdown"
                    className="text-uppercase root-menu"
                  >
                    {data.medlicuk.nodes.map(service => (
                      <li key={service.id} className="text-normalcase">
                        <NavDropdown.Item
                          as={Link}
                          to={`/${service.seo.slug}/`}
                          activeClassName="active"
                        >
                          {service.shortName}
                        </NavDropdown.Item>
                      </li>
                    ))}
                  </NavDropdown>
                  <NavDropdown
                    title="Перевізникам"
                    id="basic-nav-dropdown"
                    className="text-uppercase root-menu"
                  >
                    {data.servuk.nodes.map(service => (
                      <li key={service.id} className="text-normalcase">
                        <NavDropdown.Item
                          as={Link}
                          to={`/${service.seo.slug}/`}
                          activeClassName="active"
                        >
                          {service.shortName}
                        </NavDropdown.Item>
                      </li>
                    ))}
                  </NavDropdown>
                </>
              )}
              {lang === "ru" ? (
                <Nav.Link
                  as={Link}
                  to="/ru/kontakty/"
                  className="text-uppercase root-menu"
                  activeClassName="active"
                >
                  Контакты
                </Nav.Link>
              ) : (
                <Nav.Link
                  as={Link}
                  to="/kontakty/"
                  className="text-uppercase root-menu"
                  activeClassName="active"
                >
                  Контакти
                </Nav.Link>
              )}

              <ModalConsult
                buttonVariant={`outline-danger`}
                buttonClass={`mt-5 mb-3 my-md-0 ml-md-4 text-uppercase`}
                buttonText={lang === "ru" ? `Связаться` : `Звернутися`}
                lang={lang ? lang : "uk"}
                // slug={}
                // title={}
                // price={}
              />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
    )}
  />
)

export default Header
