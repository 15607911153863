import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import FormConsult from "../Global/FormConsult"
import { MdKeyboardArrowRight } from "react-icons/md"

const ModalConsult = ({
  buttonVariant,
  buttonText,
  buttonClass,
  props,
  slug,
  title,
  price,
  lang,
}) => {
  const [show, setShow] = useState(false)
  // const handleClose = () => setShow(false)
  // const handleShow = () => setShow(true)
  const handleToggle = () => setShow(!show)

  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulAsset(
            file: {
              fileName: { eq: "shoeib-abolhassani-ukDEbYnyDsU-unsplash.jpg" }
            }
            node_locale: { eq: "uk" }
          ) {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      `}
      render={data => {
        // Set ImageData.
        const imageData = [
          data.contentfulAsset.fluid,
          `linear-gradient(
            25deg, 
            rgba(128,0,128,0.2),
            rgba(35,65,97,0.9) 60%)`,
        ].reverse()

        return (
          <>
            <Button
              variant={buttonVariant}
              onClick={handleToggle}
              className={buttonClass}
              block
            >
              <MdKeyboardArrowRight />
              {buttonText}
            </Button>

            <Modal
              {...props}
              show={show}
              onHide={() => setShow(false)}
              // dialogClassName="modal-90w"
              aria-labelledby="contactModal"
              className="clearfix text-white"
              centered
            >
              <BackgroundImage fluid={imageData}>
                <Modal.Header closeButton>
                  <Modal.Title id="contactModal">
                    {lang === "ru"
                      ? `Получить консультацию`
                      : `Зв'язатися з фахівцем`}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <FormConsult slug={slug} title={title} price={price} />
                </Modal.Body>
              </BackgroundImage>
            </Modal>
          </>
        )
      }}
    />
  )
}

ModalConsult.defaultProps = {
  buttonText: `Консультація`,
  buttonVariant: `link`,
  buttonClass: ``,
}

export default ModalConsult
