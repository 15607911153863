import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
// import ViberFloat from "../../components/ViberFloat"
// import PhoneFloat from "../../components/PhoneFloatButton"

// import DevIcon from '../../../static/i/dev-icon.svg'
// import LogoSvg from '../../../static/i/logo-center-licenziy.svg'
import { HiCode } from "react-icons/hi"

// import { FaViber } from "react-icons/fa";

const Footer = ({ lang, slug }) => (
  <StaticQuery
    query={graphql`
      {
        background: file(relativePath: { eq: "call.jpeg" }) {
          relativePath
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        uk: allContentfulService(
          filter: { node_locale: { eq: "uk" } }
          sort: { fields: serviceId, order: ASC }
        ) {
          totalCount
          nodes {
            serviceId
            title
            shortName
            id
            price
            ltdPrice
            termFrom
            termUpTo
            validity
            taxAmount
            seo {
              slug
              metaImage {
                fluid {
                  src
                }
              }
            }
          }
        }
        ru: allContentfulService(
          filter: { node_locale: { eq: "ru" } }
          sort: { fields: serviceId, order: ASC }
        ) {
          totalCount
          nodes {
            serviceId
            title
            id
            price
            ltdPrice
            termFrom
            termUpTo
            validity
            taxAmount
            seo {
              slug
              metaImage {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <footer className="py-5">
          <div className="pt-4 pt-md-5 container">
            <div className="row">
              <div className="col-12 col-md">
                <div className="footer-title5 my-4">
                  {lang === "ru" ? `Перечень услуг` : `Перелік послуг`}
                </div>
                <ul className="list-unstyled text-small">
                  {lang === "ru"
                    ? data.ru.nodes.map(service => (
                        <li key={service.id} className="mb-3">
                          <Link
                            to={`/ru/${service.seo.slug}/`}
                            activeClassName="active"
                            className="text-muted"
                          >
                            {service.title}
                          </Link>
                        </li>
                      ))
                    : data.uk.nodes.map(service => (
                        <li key={service.id} className="mb-3">
                          <Link
                            to={`/${service.seo.slug}/`}
                            activeClassName="active"
                            className="text-muted"
                          >
                            {service.title}
                          </Link>
                        </li>
                      ))}
                </ul>
              </div>
              <div className="col-12 col-md">
                <div className="footer-title5 my-4">
                  {lang === "ru" ? `Разделы` : `Розділи`}
                </div>
                <ul className="list-unstyled text-small">
                  {lang === "ru" ? (
                    <li className="mb-3">
                      <Link
                        className="text-muted"
                        activeClassName="active"
                        to="/ru/"
                      >
                        Центр Лицензий
                      </Link>
                    </li>
                  ) : (
                    <li className="mb-3">
                      <Link
                        className="text-muted"
                        activeClassName="active"
                        to="/"
                      >
                        Центр Ліцензій
                      </Link>
                    </li>
                  )}
                  {lang === "ru" ? (
                    <li className="mb-3">
                      <Link
                        className="text-muted"
                        activeClassName="active"
                        to="/ru/kontakty/"
                      >
                        Контакты
                      </Link>
                    </li>
                  ) : (
                    <li className="mb-3">
                      <Link
                        className="text-muted"
                        activeClassName="active"
                        to="/kontakty/"
                      >
                        Контакти
                      </Link>
                    </li>
                  )}
                  {lang === "ru" ? (
                    <li className="mb-3">
                      <Link
                        className="text-muted"
                        activeClassName="active"
                        to="/ru/publichna-oferta/"
                      >
                        Публичная оферта
                      </Link>
                    </li>
                  ) : (
                    <li className="mb-3">
                      <Link
                        className="text-muted"
                        activeClassName="active"
                        to="/publichna-oferta/"
                      >
                        Публічна оферта
                      </Link>
                    </li>
                  )}
                </ul>
                <div className="footer-title5 my-4">
                  {lang === "ru" ? `График работы` : `Графік роботи`}
                </div>
                <ul className="list-unstyled text-small">
                  {lang === "ru" ? (
                    <>
                      <li className="mb-3 text-muted">
                        Понедельник - Пятница
                        <br />с 10:00 до 18:00
                      </li>
                      <li className="mb-3 text-muted">
                        Суббота
                        <br />с 10:00 до 16:00
                      </li>
                      <li className="mb-3 text-muted">
                        Воскресенье - выходной
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="mb-3 text-muted">
                        Понеділок - П'ятниця
                        <br />з 10:00 по 18:00
                      </li>
                      <li className="mb-3 text-muted">
                        Субота
                        <br />з 10:00 по 16:00
                      </li>
                      <li className="mb-3 text-muted">Неділя - вихідний</li>
                    </>
                  )}
                </ul>
              </div>
              <div className="col-12 col-md text-muted">
                <div className="footer-title5 my-4 footer-logo-animation">
                  {/* <img
                    src={LogoSvg}
                    alt="Логотип Центру Ліцензій"
                    className="footer-logo"
                  /> */}
                  {lang === "ru" ? `ЦЕНТР ЛИЦЕНЗИЙ ООО` : `ЦЕНТР ЛІЦЕНЗІЙ ТОВ`}
                </div>
                <p>
                  {lang === "ru"
                    ? `Единое окно: Лицензии в ближайшем отделении почты за 10 дней, для всей Украины`
                    : `Єдине вікно: ліцензії у відділенні пошти за 10 днів, або швидше для всієї України`}
                </p>
                {lang === "ru" ? (
                  <ul className="reasons">
                    <li>для всей Украины</li>
                    <li>без очередей</li>
                    <li>без отказов</li>
                    <li>без штрафов</li>
                    <li>постоплата</li>
                  </ul>
                ) : (
                  <ul className="reasons">
                    <li>для всієї України</li>
                    <li>без черг</li>
                    <li>без відмов</li>
                    <li>без штрафів</li>
                    <li>післяплата</li>
                  </ul>
                )}
                <p>
                  {lang === "ru"
                    ? `ТОВ "Центр Лицензий" сотрудничает с другими юрлицами и физлицами предпринимателями.`
                    : `ТОВ "Центр Ліцензій" співпрацює з іншими юридичними особами та фізичними особами - підприємцями.`}
                </p>
                <p>
                  {lang === "ru"
                    ? `03164, Киев, Украина, ул. Подлесная, 1, оф. 8`
                    : `03164, м. Київ, Україна, вул. Підлісна, 1, оф. 8`}
                </p>
                <ul className="list-unstyled">
                  {lang === "ru" ? "Звоните" : "Телефонуйте"}
                  <li className="my-2">
                    <a
                      className="phone binct-phone-number-1"
                      href="tel:+380683328808"
                    >
                      +38(068) 332-88-08
                    </a>{" "}
                    — {lang === "ru" ? "Украина" : "Україна"}
                  </li>
                  <li className="my-2">
                    <a
                      className="phone binct-phone-number-2"
                      href="tel:+380994440124"
                    >
                      +38(099) 444-01-24
                    </a>{" "}
                    — {lang === "ru" ? "Киев и область" : "Київ та область"}
                  </li>
                  <li className="my-2">
                    <a
                      className="phone binct-phone-number-3"
                      href="tel:+380678190272"
                    >
                      +38(067) 819-02-72
                    </a>{" "}
                    — {lang === "ru" ? "Чип карты" : "Чіп карти"}
                  </li>
                </ul>
                <p className="mt-3">licenzii.ukr@gmail.com</p>
                <p className="d-block pull-right">&copy; {new Date().getFullYear()}</p>
                <div className="language">
                  {lang === "ru" ? (
                    <>
                      <Link to={slug}>мова</Link> / <span>язык</span>
                    </>
                  ) : (
                    <>
                      <span>мова</span> / <Link to={`/ru${slug}`}>язык</Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <ViberFloat viber={viber} /> */}
          {/* <PhoneFloat phones={phones} /> */}
        </footer>
        <section className="container-fluid developer">
          <div className="row text-center">
            <p className="col-12 col-md pt-3 text-muted">
              {lang === "ru"
                ? `разработка и поддержка `
                : `розробка і підтримка `}
              <a
                href="https://koval.dev/?utm_source=licenziiukr&amp;utm_medium=referral&amp;utm_campaign=devlink"
                title="підтримка і розробка сайту"
                rel="noopener noreferrer nofollow"
                target="_blank"
                className="devname"
              >
                <HiCode className="devlogo" alt="розробник сайту" />
                {` `}
                koval.dev
              </a>
            </p>
          </div>
        </section>
      </>
    )}
  />
)

export default Footer
