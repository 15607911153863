import React, { useState } from "react"
// import { navigate } from "gatsby"

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const FormConsult = ({
  slug,
  title,
  price
}) => {
  const [validated, setValidated] = useState(false)

  const handleSubmit = (event) => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }
    setValidated(true)
  }
  return (
    <Form
      data-netlify="true"
      netlify-honeypot="bot-field" 
      action="/dyakuemo/" 
      method="POST" 
      name={`consult-${slug}`} 
      form-name={`consult-${slug}`} 
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
      className="mx-5"
      id="consultModalForm"
      >
      <Form.Group hidden>
        <input type="hidden" name="form-name" value={`consult-${slug}`} />
        <Form.Label>Поле для незаполнения <Form.Control type="text" name="bot-field" /></Form.Label>
        <Form.Label>Сторінка <Form.Control type="hidden" placeholder="Сторінка" name="page" value={title} /></Form.Label>
        <Form.Label>Ціна <Form.Control type="hidden" placeholder="Ціна" name="price" value={price} /></Form.Label>
      </Form.Group>

      <Form.Group controlId="formPhone">
        <Form.Label>Ваш телефон *</Form.Label>
        <Form.Control type="tel" placeholder="+380" name="phone" required />
        <Form.Control.Feedback type="invalid">
          вкажіть номер, будь ласка
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="formName">
        <Form.Label>Ім'я</Form.Label>
        <Form.Control type="text" placeholder="як до Вас звернутися?" name="name" />
      </Form.Group>

      <Form.Group controlId="formMessage">
        <Form.Label>Повідомлення</Form.Label>
        <Form.Control as="textarea" rows="3" name="message" />
      </Form.Group>

      <Form.Group controlId="formCheckbox" className="mt-5">
        <Form.Check type="checkbox" label="згода з правилами сайту" name="agree" required />
        <Form.Control.Feedback type="invalid">
          прийміть, будь ласка, правила сайту
        </Form.Control.Feedback>
      </Form.Group>

      <Row className="mb-5">
        <Col>
          <Button variant="warning" type="submit" size="lg" block>Надіслати</Button>
        </Col>
      </Row>
    </Form>
  )
}

FormConsult.defaultProps = {
  slug: ``,
  title: ``,
  price: `0`,
}

export default FormConsult